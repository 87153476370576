import { ZDTProduct } from '@zalora/doraemon-ts';
import apiClient from 'api/APIClient';
import { ZDTPromotion } from 'types/ZDT';
import { ApiResponse } from 'types/api/ApiResponse';

const BASE_PATH = '/v1/promotions';

/**
 * Get basic promotion list per config sku (for product overlay & price pill)
 * https://docs.zalora.io/s/doraemon#/promotions/Controller.Get%20applicable%20promotions%20per%20Config-SKUs.
 */
export const getPromoByConfigSkuList = async (skus: string[]) => {
  try {
    const response = await apiClient.get(BASE_PATH, {
      credentials: 'include',
      params: {
        config_sku: skus,
      },
    });

    const resJSON: ApiResponse<ZDTPromotion.PromotionMap> = await response.json();

    return resJSON?.data.Promotions;
  } catch (error) {}
};

/**
 * Get promotion list by simple sku
 * https://docs.zalora.io/s/doraemon#/promotions/Controller.Get%20Product%20Promotions%20for%20hybrid%20SKU%20feature
 */
export const getPromoBySimpleSku = async (sku: string, configSku: string) => {
  try {
    const response = await apiClient.get(`${BASE_PATH}/products/simple/${sku}`, {
      credentials: 'include',
    });

    const resJSON: ApiResponse<ZDTProduct.ProductPromotions> = await response.json();

    return resJSON?.data?.Promotions?.[configSku];
  } catch (error) {}
};

/**
 * Get promotion list by config sku
 * https://docs.zalora.io/s/doraemon#/promotions/Controller.Get%20product%20promotions%20by%20Config-SKU.
 */
export const getPromoByConfigSku = async (sku: string) => {
  try {
    const response = await apiClient.get(`${BASE_PATH}/products/${sku}`, {
      credentials: 'include',
    });

    const resJSON: ApiResponse<ZDTProduct.PromotionList> = await response.json();

    return resJSON?.data?.Promotions;
  } catch (error) {}
};
