import { OptimizelyContext } from '@optimizely/react-sdk';
import { useContext } from 'react';
import FeatureFlags from 'constants/feature-flags';
import { useInvokeCallbackOnceWhen } from 'hooks/useInvokeCallbackOnceWhen';
import { callDecisionMethod } from './useOptimizelyDecision';

/**
 * This hook is used to send optimizely decision to optimizely server for only experiments
 * It has cached by cookie for 1 day to prevent sending request to optimizely server too much
 */
const useSendOptimizelyDecisionOnce = (
  flag: FeatureFlags.OptimizelyFlag | string,
  isMatchingCondition?: () => boolean,
) => {
  const { optimizely } = useContext(OptimizelyContext);

  const shouldInvokeCallback =
    !!optimizely && (typeof isMatchingCondition === 'function' ? isMatchingCondition() : true);

  useInvokeCallbackOnceWhen(() => {
    callDecisionMethod(optimizely, flag);
  }, shouldInvokeCallback);
};

export default useSendOptimizelyDecisionOnce;
