import { ZDTInteractiveStore, ZDTCatalog } from '@zalora/doraemon-ts';
import { simpleFetch } from './APIClient';

/**
 * Endpoints that should be fetched with sellerURLKey
 */
export const enum SellerKeyEndpoint {
  getInfoByUrlKey = '/v1/dynseller/url/{sellerURLKey}/datajet',
}

/**
 * Endpoints that should be fetched with sellerId
 */
export const enum SellerIdEndpoint {
  getInfo = '/v1/dynseller/{sellerID}/datajet',
  getFilterFields = '/v1/dynseller/{sellerID}/datajet/products/filter',
  getProducts = '/v1/dynseller/{sellerID}/datajet/products/list',
  getHomeSection = '/v1/dynseller/{sellerID}/datajet/section/home',
  getProductSection = '/v1/dynseller/{sellerID}/datajet/section/products',
  getSalesSection = '/v1/dynseller/{sellerID}/datajet/section/sales',
}

interface EndpointResponse {
  // SellerKeyEndpoint
  [SellerKeyEndpoint.getInfoByUrlKey]: ZDTInteractiveStore.Store;

  // SellerIdEndpoint
  [SellerIdEndpoint.getInfo]: ZDTInteractiveStore.Store;
  [SellerIdEndpoint.getFilterFields]: ZDTCatalog.FilterResponse;
  [SellerIdEndpoint.getProducts]: ZDTCatalog.ProductResponse;
  [SellerIdEndpoint.getHomeSection]: ZDTInteractiveStore.Section;
  [SellerIdEndpoint.getProductSection]: ZDTInteractiveStore.Section;
  [SellerIdEndpoint.getSalesSection]: ZDTInteractiveStore.Section;
}

export const callById = async <T extends SellerIdEndpoint>(
  endpoint: T,
  sellerID: string,
  config?: Parameters<typeof simpleFetch>[1],
) => {
  const targetUrl = endpoint.replace('{sellerID}', sellerID);
  const response = await simpleFetch<EndpointResponse[T]>(targetUrl, config);

  return response;
};

export const callByKey = async <T extends SellerKeyEndpoint>(
  endpoint: T,
  sellerKey: string,
  config?: Parameters<typeof simpleFetch>[1],
) => {
  const targetUrl = endpoint.replace('{sellerURLKey}', sellerKey);
  const response = await simpleFetch<EndpointResponse[T]>(targetUrl, config);

  return response;
};
