import { default as NextImage, ImageProps } from 'next/image';
import { getStaticAsset } from 'utils/domains';

const NEXT_ASSET_PATH = '/static-assets';

const Image = ({ src, ...rest }: ImageProps) => {
  let srcImage = src || '';
  const isInternalImage = srcImage.toString().startsWith(NEXT_ASSET_PATH);

  if (isInternalImage) {
    srcImage = getStaticAsset(src as string);
  }

  return (
    <NextImage
      {...rest}
      src={srcImage}
    />
  );
};

export default Image;
