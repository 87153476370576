import { ZDTProduct } from '@zalora/doraemon-ts';
import apiClient from 'api/APIClient';
import { buildQueryString, getAPIError } from 'api/util';
import { ApiResponse } from 'types/api/ApiResponse';
import { UpdateWishlistResponse, UpdateSizeWishlistItemParams } from 'types/api/wishlist';
import { captureError } from 'utils/raven';

/**
 * Add a product into wishlist
 *
 * @returns wishlist item, however, it is empty since the API does not return the item after adding
 */
export const addToWishlist = async (sku: string, selectedSizeSystem?: string) => {
  const body = new FormData();

  body.append('simpleSku', sku);

  if (selectedSizeSystem) {
    body.append('selectedSizeSystem', selectedSizeSystem);
  }

  const res = await apiClient.post(`/v1/wishLists/items`, {
    credentials: 'include',
    body,
  });

  if (!res.ok) {
    throw await getAPIError(res);
  }

  const data = await res.json();

  return data.data as ZDTProduct.WishListItem;
};

export const removeWishlistItem = async (params: { simpleSku?: string; configSku?: string }) => {
  const requestPrams = { ...params };

  if (requestPrams.simpleSku) {
    delete requestPrams.configSku;
  }

  await apiClient.delete(`/v1/wishLists/items`, {
    credentials: 'include',
    params: requestPrams,
  });
};

export const updateSizeWishlistItem: (
  params: UpdateSizeWishlistItemParams,
) => Promise<UpdateWishlistResponse | undefined> = async ({
  oldSku,
  newSku,
  selectedSizeSystem,
}) => {
  try {
    const queryString = buildQueryString({
      oldSku,
      newSku,
    });
    const body = new FormData();

    if (selectedSizeSystem) {
      body.append('selectedSizeSystem', selectedSizeSystem);
    }

    const path = queryString ? `/v1/wishLists/items?${queryString}` : `/v1/wishLists/items`;
    const res = await apiClient.put(path, {
      credentials: 'include',
      body,
    });
    const data = await res.json();

    return data as UpdateWishlistResponse;
  } catch (error) {
    captureError('Error when updating size wishlist item', { error, tag: 'submit-request' });
  }
};

/**
 * Get wishlist with pagination
 */
export const getWishlist = async ({ limit, offset = 0 }: { limit: number; offset?: number }) => {
  const params = {
    limit,
    offset,
  };
  const res = await apiClient.get(`/v1/wishLists/wishlist`, {
    credentials: 'include',
    params,
  });

  if (!res.ok) {
    throw await getAPIError(res);
  }

  const { data }: ApiResponse<ZDTProduct.WishList> = await res.json();

  return data;
};

/**
 * This is a hack to get the first wishlist item which is the item just added recently
 * Because API does not return it from adding endpoint. we have to do this cheat.
 */
export const getFirstWishlistItem = async () => {
  try {
    const wishlist = await getWishlist({ limit: 1, offset: 0 });

    return wishlist.Items?.[0] || null;
  } catch (err) {
    return null;
  }
};
