import {
  OptimizelyContext,
  OptimizelyDecideOption,
  OptimizelyDecision,
  ReactSDKClient,
  useDecision,
} from '@optimizely/react-sdk';
import { useContext, useEffect, useMemo } from 'react';
import { DEFAULT_ENABLE_VARIATION } from 'constants/optimizely';
import { useInvokeCallbackOnceWhen } from './useInvokeCallbackOnceWhen';

export const isOptimizelyFlagEnabled = (
  decision: OptimizelyDecision,
  enableVariationValue = DEFAULT_ENABLE_VARIATION,
) => {
  const variationKey = decision.variationKey;
  const isEnabled = decision.enabled;

  return isEnabled && variationKey === enableVariationValue;
};

export const useOptimizelyDecision = (
  key: string,
  enableVariationValue = DEFAULT_ENABLE_VARIATION,
  shouldSendDecision = true,
) => {
  const decision = useOptimizelyRawDecision(key, shouldSendDecision);

  return isOptimizelyFlagEnabled(decision, enableVariationValue);
};

export const useOptimizelyRawDecision = (key: string, shouldSendDecision: boolean) => {
  const { optimizely } = useContext(OptimizelyContext);
  const [decision] = useDecision(key, {
    decideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
  });

  useInvokeCallbackOnceWhen(() => {
    if (shouldSendDecision) {
      callDecisionMethod(optimizely, key);
    }
  }, !!optimizely);

  return decision;
};

export const callDecisionMethod = (optimizely: ReactSDKClient | null, key: string) => {
  optimizely?.onReady().then(() => {
    if (sessionStorage.getItem(key)) {
      return;
    }

    optimizely.decide(key);
    sessionStorage.setItem(key, '1');
  });
};

export const useSendOptimizelyDecisionForKeys = (keys: string[]) => {
  const { optimizely } = useContext(OptimizelyContext);

  const isClientReady = optimizely?.isReady();

  const decisions = useMemo(
    () => ({
      decisions:
        isClientReady && optimizely
          ? optimizely.decideForKeys(keys, [OptimizelyDecideOption.DISABLE_DECISION_EVENT])
          : {},
    }),
    [isClientReady, keys, optimizely],
  );

  useEffect(() => {
    keys.forEach((key) => {
      callDecisionMethod(optimizely, key);
    });
  }, [keys, optimizely]);

  return decisions;
};
