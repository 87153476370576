import { useRouter } from 'next/router';
import { unstable_serialize as unstableSerialize } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { QueryParams } from 'api/APIClient';
import { ProductListingApi, getSponsoredProducts } from 'api/catalog';
import { CATALOG_HAVING_SPONSORED_PRODUCTS, SPONSORED_PRODUCT_PER_PAGE } from 'constants/catalog';
import { PageInfo } from 'utils/catalog/catalog-page-type';
import { getUtmSource } from 'utils/segment-analytic';
import useCatalogFetcher from './useCatalogFetcher';
import { useDatajet } from './useDatajet';
import { useEnrichAttributes } from './useEnrichAttributes';

const useCatalogProducts = ({
  pageInfo,
  params,
  isSearchCrawler,
}: {
  pageInfo: PageInfo;
  params: QueryParams;
  isSearchCrawler: boolean;
}) => {
  const fetcher = useCatalogFetcher(pageInfo);
  const {
    isLoading: isEnrichAttributeExpLoading,
    abTestParam: enrichABTestParam,
    isEnrichAttributeEnabled,
  } = useEnrichAttributes();
  const { abTestParam: djABTestParam } = useDatajet();

  const router = useRouter();

  const shouldFetchSponsoredProducts =
    !isSearchCrawler && CATALOG_HAVING_SPONSORED_PRODUCTS.includes(pageInfo.catalogType);

  const productSwrKey =
    !isEnrichAttributeExpLoading && (isSearchCrawler || fetcher)
      ? unstableSerialize([
          'products/list',
          params,
          isEnrichAttributeEnabled ? enrichABTestParam : djABTestParam,
        ])
      : null;

  const {
    data: products,
    isLoading: isLoadingProducts,
    error,
  } = useSWRImmutable(productSwrKey, () => fetcher?.(ProductListingApi.list, params), {
    keepPreviousData: !isSearchCrawler,
  });

  const sponsoredProductSwrKey = shouldFetchSponsoredProducts
    ? unstableSerialize(['sponsored-products/list', params])
    : null;

  const { data: sponsoredProducts, isLoading: isLoadingSponsoredProducts } = useSWRImmutable(
    sponsoredProductSwrKey,
    () => {
      const page = Number(router.query.page || 1);

      return getSponsoredProducts({
        ...params,
        offset: (page - 1) * SPONSORED_PRODUCT_PER_PAGE,
        limit: SPONSORED_PRODUCT_PER_PAGE,
        utm_source: getUtmSource(),
      });
    },
    { keepPreviousData: true },
  );

  return {
    // if isSearchCrawler is true, date will be got from server side and storing in cache of swr already,
    // don't need to check for loading
    isLoading: !isSearchCrawler && (isLoadingProducts || isLoadingSponsoredProducts),
    error,
    products,
    sponsoredProducts,
  };
};

export default useCatalogProducts;
