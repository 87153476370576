import useSWRImmutable from 'swr/immutable';
import { getPromoByConfigSkuList } from 'api/promotions';
import { ZDTPromotion } from 'types/ZDT';
import { splitToChunks } from 'utils/array';

const SKU_LIMIT = 60;

type Promotions = ZDTPromotion.PromotionMap['Promotions'];

export const parallelFetch = async (skus: string[]) => {
  const skusPerChunk = splitToChunks(skus, SKU_LIMIT);

  const res = await Promise.all(skusPerChunk.map((chunk) => getPromoByConfigSkuList(chunk)));

  if (!res) {
    return {};
  }

  return res.reduce((prev: Promotions, chunk) => (chunk ? { ...prev, ...chunk } : prev), {});
};

export const usePromotion = (skus: string[]) => {
  const promotionData = useSWRImmutable(
    skus.length > 0 ? ['/v1/promotions', skus] : null,
    ([, skus]) => parallelFetch(skus),
  );

  return promotionData;
};
