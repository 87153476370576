import { OptimizelyDecision, useDecision, OptimizelyDecideOption } from '@optimizely/react-sdk';
import { useMemo } from 'react';
import FeatureFlags from 'constants/feature-flags';
import { FALSE } from 'constants/optimizely';
import { useEnabledBobFeatureFlag } from 'hooks/api/useEnabledBobFeatureFlag';
import useEnvConfiguration from 'hooks/useEnvConfiguration';
import useSendOptimizelyDecisionOnce from 'hooks/useSendOptimizelyDecisionOnce';

const VARIABLE_KEY = 'dj_config';

/**
 * This hook is used to get AB Test Datajet on Catalog and Search Page
 * Should be cleaned up (remove decide call) after the AB Test is concluded
 */
export const useDatajet = (sendActiveRequest = false) => {
  const { CC = '' } = useEnvConfiguration();
  // BOB Feature Flags
  const isDJCatalogEnabled = useEnabledBobFeatureFlag(FeatureFlags.Bob.ENABLE_DJ_CATALOG);

  // Optimizely Feature Flags
  const [catalogDecision] = useDecision(FeatureFlags.Optimizely.ENABLE_DJ_CATALOG, {
    decideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
  });

  // Send Optimizely Decision events
  useSendOptimizelyDecisionOnce(
    FeatureFlags.Optimizely.ENABLE_DJ_CATALOG,
    () => !!sendActiveRequest && !!isDJCatalogEnabled,
  );

  // Calculate AB Test Param
  const abTestParam = useMemo(() => {
    const abTestParamOfCatalog = getABTestParam(catalogDecision, CC);

    if (abTestParamOfCatalog) {
      return abTestParamOfCatalog;
    }

    return '';
  }, [catalogDecision, CC]);

  const isEnabled = useMemo(
    () =>
      isEnabledDatajet({
        isDJCatalogEnabled,
        catalogDecision,
      }),
    [isDJCatalogEnabled, catalogDecision],
  );

  return {
    abTestParam,
    isEnabled,
  };
};

export const getABTestParam = (decision: OptimizelyDecision, countryCode: string) => {
  const { enabled, variables } = decision;

  if (!enabled || !variables) {
    return '';
  }

  /**
   * Variable is configuring on Optimizely by format
    {
      "abtest": "djAbTest_True|uuid:",
      "sg":"8b47176d-6da1-4b04-be54-6b315d351d1c",
      "my":"260ab724-9745-40f7-bd95-f37639c078d2",
      "hk":"1bd1c747-9f25-4462-8323-599da50b3528",
      "tw":"ab92c686-073e-453e-9ff7-b02b9ae614c0",
      "ph":"26002942-7434-48c3-b740-b75b8b5adc99",
      "id":"e59b1f02-18e6-42df-b0c2-3f374ab1b117"
    }
   */
  const variantVariables = variables[VARIABLE_KEY] as Record<string, string>;

  if (!variantVariables) {
    return '';
  }

  return `${variantVariables.abtest}${variantVariables[countryCode.toLowerCase()]}`;
};

// a weird condition because of requirement
// - If BOB FF = ON, Optimizely FF = OFF → Feature 100% Rollout.
// - If BOB FF = ON, Optimizely FF = ON → Feature is running A/B tests
export const isEnabledDatajet = ({
  isDJCatalogEnabled,
  catalogDecision,
}: {
  isDJCatalogEnabled: boolean | undefined;
  catalogDecision: OptimizelyDecision;
}) => {
  if (!isDJCatalogEnabled) {
    return false;
  }

  // --------------------------------------------
  // if one of the corresponding FF on catalog page is enabled,
  // we should check whether it's enabled on Optimizely

  // the Optimizely experiment turned off || it turns on and has variant !== FALSE (= TRUE)
  if (!catalogDecision.enabled || catalogDecision.variationKey !== FALSE) {
    return true;
  }

  return false;
};
