import { useMemo } from 'react';
import { EnvConfiguration } from 'utils/env-configuration';
import isServer from 'utils/is-server';

/**
 * This hook will return the configuration from env
 */
const useEnvConfiguration = (): EnvConfiguration => {
  return useMemo(() => {
    return (isServer() ? (process.env as EnvConfiguration) : window.__ENV__) ?? {};
  }, []);
};

export default useEnvConfiguration;
