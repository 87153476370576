import { ZDTProduct, ZDTCatalog } from '@zalora/doraemon-ts';
import { QueryParams } from 'api/APIClient';
import { CatalogFilterName, FILTER_REGULAR_GROUP, FilterName } from 'constants/catalog';
import { QuickFilters } from 'types/api/QuickFilters';
import { CatalogType, PIPPageInfo, PageInfo } from './catalog-page-type';

export const getFilterByGroup = (filters: ZDTProduct.Filter[], pageInfo: PageInfo) => {
  const regularFilters: ZDTProduct.Filter[] = [];
  const additionalFilters: ZDTProduct.Filter[] = [];

  filters.forEach((filter) => {
    if (
      !filter.Id ||
      // We don't want to show a filter if it has no options except for category filter,
      //because the options for category filter will be retrieved from the parent category API call
      ((filter.Options?.length || 0) < 1 && filter.Id !== CatalogFilterName.CATEGORY)
    ) {
      return;
    }

    if (filter.Group !== FILTER_REGULAR_GROUP) {
      additionalFilters.push(filter);

      return;
    }

    if (shouldShowRegularFilter(filter.Id, pageInfo)) {
      regularFilters.push(filter);
    }
  });

  return { regularFilters, additionalFilters };
};

export const getFilterParams = (
  fieldId: string | undefined,
  value: ZDTProduct.FilterOptions | ZDTProduct.FilterOptions[],
): QueryParams => {
  if (!fieldId) {
    return {};
  }

  if (Array.isArray(value)) {
    return {
      [fieldId]: value.map((v) => v.Value).filter(Boolean) as string[],
    };
  }

  if (value.Value) {
    return {
      [fieldId]: value.Value,
    };
  }

  return {};
};

export const shouldShowZaloraVipFilter = (
  filter: ZDTProduct.Filter | undefined,
): filter is ZDTProduct.Filter => {
  return filter?.Options?.some((opt) => opt.Value === 'only') || false;
};

export const getQuickFilters = (
  filters: ZDTProduct.Filter[] | undefined | null,
  pageInfo: PageInfo,
) => {
  if (!filters || !filters.length) {
    return {};
  }

  return filters.reduce((prev, filter) => {
    const { Id } = filter;

    if (!Id || !shouldShowRegularFilter(Id, pageInfo)) {
      return prev;
    }

    switch (Id) {
      case CatalogFilterName.MEMBERSHIP_ELIGIBLE: {
        return {
          ...prev,
          shouldShowZaloraVipFilterButton: shouldShowZaloraVipFilter(filter),
          zaloraVipFilter: filter,
        } as QuickFilters;
      }

      case CatalogFilterName.BRAND: {
        return {
          ...prev,
          brandFilter: filter,
        } as QuickFilters;
      }

      case CatalogFilterName.COLOR: {
        return {
          ...prev,
          colorFilter: filter,
        } as QuickFilters;
      }

      case CatalogFilterName.SIZE: {
        return {
          ...prev,
          sizeFilter: filter,
        } as QuickFilters;
      }

      default: {
        return prev;
      }
    }
  }, {} as QuickFilters);
};

export const getPersistingParams = (queryString?: string) => {
  if (!queryString) {
    return '';
  }

  const query = new URLSearchParams(queryString);
  const removeParams = [CatalogFilterName.CATEGORY, CatalogFilterName.SIZE, 'page'];

  removeParams.forEach((param) => {
    query.delete(param);
  });

  return query.toString();
};

export const shouldShowRegularFilter = (filterId: string, pageInfo: PageInfo) => {
  const { catalogType } = pageInfo;

  switch (catalogType) {
    case CatalogType.CATALOG: {
      const { brandId } = pageInfo;

      // do not show brand filter on brand page
      if (brandId && filterId === CatalogFilterName.BRAND) {
        return false;
      }

      return true;
    }

    case CatalogType.ZIS: {
      // Do not show store filter on ZIS
      if (filterId === CatalogFilterName.SELLER) {
        return false;
      }

      return true;
    }

    case CatalogType.PIP: {
      return shouldShowFilterOnPIP(filterId, pageInfo);
    }

    default: {
      return true;
    }
  }
};

const shouldShowFilterOnPIP = (filterId: string, pageInfo: PIPPageInfo) => {
  const { productIndexParams } = pageInfo;

  if (filterId in productIndexParams) {
    const value = productIndexParams[filterId as keyof typeof productIndexParams];

    if (typeof value === 'string' || Array.isArray(value)) {
      return value.length === 0;
    }
  }

  return true;
};

export const getFilterByType = (
  filters: ZDTCatalog.FilterResponse,
  type: FilterName | string | undefined,
) => {
  return filters.Filters?.find((filter) => filter.Id === type);
};

export const getFilterById = (filters: ZDTCatalog.FilterResponse, id: string) => {
  return filters.Filters?.find((filter) => filter.Id === id);
};

export const getFilterLabel = (option: ZDTProduct.FilterOptions, key: string) => {
  const optionLabel = option.Label || '';
  const filterLabel = key === CatalogFilterName.SIZE ? optionLabel.replace(/_/g, ' ') : optionLabel;

  return filterLabel;
};

export const removeDuplicatedFilterOptions = (filterOptions: ZDTProduct.FilterOptions[]) => {
  return filterOptions.filter(
    (option, index) => filterOptions.findIndex((o) => o.Value === option.Value) === index,
  );
};
