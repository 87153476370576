export const SEARCH_PARAMS = ['q', 'search_method', 'rewrite', 'origin', 'classifier'];

export const enum SEARCH_EVENTS {
  SEARCH_SUGGESTION = 'search-suggestion',
  SUGGEST_PRODUCT = 'suggest-product',
  RECENT_SEARCHES = 'recent-searches',
  SUBMIT_SEARCH = 'search-bar',
}

export const enum SEARCH_METHODS {
  SEARCH_SUGGESTION = 'search suggestion',
  PRODUCT_SUGGESTION = 'product suggestion',
  RECENT_SEARCHES = 'recent searches',
  POPULAR_SEARCH = 'popular search',
  SEARCH_TEXT_PROMOTION = 'search text promotion',
  AUTOCOMPLETE_SEARCH = 'autocomplete search',
  SUBMIT_SEARCH = 'submit search',
  VISUAL_SEARCH = 'visual search',
}

export const SEARCH_METHOD_MAPPING = {
  [SEARCH_EVENTS.SEARCH_SUGGESTION]: SEARCH_METHODS.SEARCH_SUGGESTION,
  [SEARCH_EVENTS.RECENT_SEARCHES]: SEARCH_METHODS.RECENT_SEARCHES,
  [SEARCH_EVENTS.SUGGEST_PRODUCT]: SEARCH_METHODS.PRODUCT_SUGGESTION,
  [SEARCH_EVENTS.SUBMIT_SEARCH]: SEARCH_METHODS.SUBMIT_SEARCH,
};

export const AVAILABLE_SEARCH_EVENTS = Object.keys(SEARCH_METHOD_MAPPING);

export const SEARCH_SUGGESTION_LIMIT = 5;

export const SEARCH_INPUT_ID = 'header_search';
