export const isNonEmptyArray = <T>(array: Nullishable<T[] | T>): array is T[] =>
  Array.isArray(array) && array.length > 0;

export const getFirstOrSingle = <T>(params: T[] | T) => {
  if (isNonEmptyArray(params)) {
    return params[0];
  }

  return params;
};

export const getFirstAsArray = <T>(array: T[]): T[] => {
  if (!isNonEmptyArray(array)) {
    return [];
  }

  return array.slice(0, 1);
};

export const isSorted = (array: number[], direction: 'asc' | 'desc') => {
  return array.every((value, index, array) => {
    if (index === 0) {
      return true;
    }

    if (direction === 'asc') {
      return value >= array[index - 1];
    }

    return value <= array[index - 1];
  });
};

export const splitToChunks = <T>(array: T[], chunkSize: number) => {
  const chunks: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);

    chunks.push(chunk);
  }

  return chunks;
};
