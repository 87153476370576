import { ZDTProduct } from '@zalora/doraemon-ts';
import { getWishlist } from 'api/wishlist';
import { SelectedSizeData } from 'types/SelectedSizeData';
import {
  getSimpleBySimpleSku,
  getSizesFromSimples,
  getSizeSystemBrand,
  isOneSizeProduct,
} from './product';

const DEFAULT_WISHLIST_TOTAL = 0;
const PER_PAGE = 500; // there are 99% of users have less than 500 items in their wishlist
const LIMIT = 2000; // cover more than 99% of the cases

export const isSkuInWishlist = (sku?: string | null, wishlistItems?: ZDTProduct.WishListItem[]) => {
  if (!sku || !wishlistItems) {
    return false;
  }

  return (wishlistItems || []).some((item) => item.ConfigSku === sku || item.SimpleSku === sku);
};

export const getSelectedSizeData = (wishlistItem: ZDTProduct.WishListItem): SelectedSizeData => {
  const sizes = getSizesFromSimples(wishlistItem.Product);

  if (!wishlistItem.SimpleSku || !Object.values(sizes).length) {
    return {};
  }

  const selectedSystem = wishlistItem.SelectedSizeSystem;
  const sizeSystemBrand = getSizeSystemBrand(wishlistItem.Product);
  const sizesBrand = sizes[sizeSystemBrand] || Object.values(sizes)[0];

  if (isOneSizeProduct(wishlistItem.Product)) {
    return {
      size: sizesBrand[0],
      system: selectedSystem,
    };
  }

  const selectedSimple = getSimpleBySimpleSku(wishlistItem.Product, wishlistItem.SimpleSku);
  const selectedSizeBrand = sizesBrand?.find((size) => size.Label === selectedSimple?.Size);
  const selectedSystemSizes = sizes[selectedSystem];
  const selectedSize = selectedSystemSizes
    ? selectedSystemSizes?.find((size) => size.Position === selectedSizeBrand?.Position)
    : selectedSizeBrand;

  return {
    size: selectedSize,
    system: selectedSystem,
  };
};

export const getWishlistTotal = (wishlist: ZDTProduct.WishList | undefined) => {
  return wishlist?.TotalCount || DEFAULT_WISHLIST_TOTAL;
};

export const getWishlistItems = (wishlist: ZDTProduct.WishList | undefined) => {
  if (!wishlist) {
    return [];
  }

  return wishlist.Items || [];
};

export const removeWishlistItemFromWishlistData = (
  wishlistData: ZDTProduct.WishList | undefined,
  itemId: string,
) => {
  if (!wishlistData) {
    return wishlistData;
  }

  const wishlistItems = getWishlistItems(wishlistData);
  const updatedItems = wishlistItems.filter((item) => item.ItemId !== itemId);
  const totalCount = getWishlistTotal(wishlistData) - 1;

  const updatedWishlistData: ZDTProduct.WishList = {
    ...wishlistData,
    Items: updatedItems,
    TotalCount: totalCount,
  };

  return updatedWishlistData;
};

export const removeSkuFromWishlistData = (
  wishlistData: ZDTProduct.WishList | undefined,
  simpleOrConfigSku: string,
) => {
  if (!wishlistData) {
    return wishlistData;
  }

  const wishlistItems = getWishlistItems(wishlistData);

  const updatedItems = wishlistItems.filter(
    (item) => !(item.ConfigSku === simpleOrConfigSku || item.SimpleSku === simpleOrConfigSku),
  );

  const numberOfRemovedItems = wishlistItems.length - updatedItems.length;
  const totalCount = getWishlistTotal(wishlistData) - numberOfRemovedItems;

  const updatedWishlistData: ZDTProduct.WishList = {
    ...wishlistData,
    Items: updatedItems,
    TotalCount: totalCount,
  };

  return updatedWishlistData;
};

export const updateWishlistItemSize = ({
  wishlist,
  wishlistId,
  simpleSKU,
  selectedSizeSystem,
}: {
  wishlist: ZDTProduct.WishList | undefined;
  wishlistId: string;
  simpleSKU: string;
  selectedSizeSystem: string;
}) => {
  if (!wishlist) {
    return wishlist;
  }

  const items = getWishlistItems(wishlist);
  const itemIndex = items.findIndex((item) => item.ItemId === wishlistId);

  if (itemIndex === -1) {
    return wishlist;
  }

  const item: ZDTProduct.WishListItem = {
    ...items[itemIndex],
    SimpleSku: simpleSKU,
    SelectedSizeSystem: selectedSizeSystem,
  };

  items[itemIndex] = item;

  const updatedWishlist: ZDTProduct.WishList = {
    ...wishlist,
    Items: items,
  };

  return updatedWishlist;
};

export const loadFullWishlist = async () => {
  const result = await getWishlist({ limit: PER_PAGE });
  const total = getWishlistTotal(result);

  if (total > PER_PAGE) {
    const maxLimit = Math.min(LIMIT, total);
    const numberOfRemainingItems = maxLimit - PER_PAGE;
    const numberOfRequests = Math.ceil(numberOfRemainingItems / PER_PAGE);

    const requests = Array.from({ length: numberOfRequests }, (_, index) =>
      getWishlist({ limit: PER_PAGE, offset: (index + 1) * PER_PAGE }),
    );

    const responses = await Promise.all(requests);

    const initialItems = getWishlistItems(result);

    result.Items = [...initialItems, ...responses.flatMap(getWishlistItems)];
  }

  return result;
};
