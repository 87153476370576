import useSWRImmutable from 'swr/immutable';
import useUser from 'hooks/api/useUser';
import { loadFullWishlist } from 'utils/wishlist';

const WISHLIST_ENDPOINT = '/v1/wishLists/wishList';

export const useWishlist = (shouldLoadFull?: boolean) => {
  const { data: user } = useUser();

  return useSWRImmutable(user ? [WISHLIST_ENDPOINT, shouldLoadFull] : null, () =>
    loadFullWishlist(),
  );
};
