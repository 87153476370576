import { OptimizelyDecideOption, useDecision } from '@optimizely/react-sdk';
import { useMemo } from 'react';
import FeatureFlags from 'constants/feature-flags';
import { NOT_APPLICABLE } from 'constants/optimizely';
import { useEnabledBobFeatureFlag } from 'hooks/api/useEnabledBobFeatureFlag';
import { getEnvConfiguration } from 'utils/env-configuration';
import { getABTestParam } from './useDatajet';

export const useEnrichAttributes = () => {
  const CC = getEnvConfiguration('CC') || '';
  const isEnrichAttributeEnabled = useEnabledBobFeatureFlag(
    FeatureFlags.Bob.LOTUS_ENABLE_ENRICHED_ATTRIBUTES,
  );

  const [enrichAttributeDecision, clientReady] = useDecision(
    FeatureFlags.Optimizely.LOTUS_ENABLE_ENRICHED_ATTRIBUTES,
    {
      decideOptions: [OptimizelyDecideOption.DISABLE_DECISION_EVENT],
    },
  );

  const abTestParam = useMemo(() => {
    return getABTestParam(enrichAttributeDecision, CC);
  }, [enrichAttributeDecision, CC]);

  const isExperimentRunning = enrichAttributeDecision.enabled;
  const variationKey = enrichAttributeDecision.variationKey;

  return {
    abTestParam,
    isEnrichAttributeEnabled:
      isEnrichAttributeEnabled && (!isExperimentRunning || variationKey !== NOT_APPLICABLE),
    variationKey,
    isLoading: !clientReady,
  };
};
